html,
body,
#root,
div [data-testid='page-layout-wrapper'] {
  margin: 0px;
  padding: 0px;
  height: 100%;
}

form fieldset {
  margin-bottom: 1rem;
}
